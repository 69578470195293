@import '../../index.scss';

.experience {
  display: flex;
  border: $border-width solid $purple;
  flex-direction: column;
  border-radius: 10px;
  padding-bottom: 10px;
  .experience-organization, .experience-position {
    h4, h5 {
      margin: 0px;
    }
  }
  .experience-organization {
    padding: 10px;
    border-bottom: $border-width solid $purple;
    color: $yellow;
    background: $dark-purple;

    font-size: 16px;
    h4 {
      font-weight: 500;
    }
  }
  .experience-position {
    padding: 10px 10px 0px 10px;
    h5 {
      font-size: 14px;
      font-weight: 500;
    }

    .year {
      color: rgb(189, 189, 189);
      font-size: 12px;
    }
  }
  .experience-description {
    padding: 0px 10px 10px 10px;
    color: rgb(189, 189, 189);
    font-family: "Open Sans", sans-serif;
    p {
      margin-bottom: 0px;
    }
  }
}