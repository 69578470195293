@import '../../index.scss';

.big-link {
  @include transition;
  display: flex;
  text-decoration: none;
  align-items: stretch;
  color: $white;
  max-width: 725px;
  min-height: 100px;
  border: $border-width solid $purple;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 25px;
  position: relative;
  .big-link-icon {
    font-size: 50px;
    min-width: 25%;
    background: $dark-purple;
    border-right: $border-width solid $purple;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition;
  }
  .big-link-text {
    min-width: 0px;
    width: 100%;
    color: $yellow;
    font-size: 30px;
    font-family: 'Nanum Pen Script', sans-serif;
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
  .big-link-arrow {
    font-size: 30px;
    position: absolute;
    top: 0;
    right: 15px;
    top: 30px;
    color: $purple;
  }
  &.unlocked:hover, &.unlocked:focus {
    background: $dark-purple;
    outline: none;
    cursor: pointer;
    .big-link-icon {
      font-size: 65px;
    }
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 0px;
    border-radius: 0px;
    border-top: 0px;
    align-items: center;
    border-left: 0px;
    border-right: 0px;
    .big-link-icon {
      font-size: 30px;
      width: 75px;
      margin-left: 10px;
      height: 75px;
      max-width: 75px;
      min-width: 75px;
      max-height: 75px;
      min-height: 75px;
      border-radius: 50%;
      border: $border-width solid $purple;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition;
    }
    &.unlocked:hover, &.unlocked:focus {
      .big-link-icon {
        font-size: 40px;
      }
    }
  }
}