@import '../../index.scss';

.skills {
  border: $border-width solid $purple;
  border-radius: 10px;
  .skills-header {
    background: $dark-purple;
    padding: 10px;
    color: $yellow;
    font-weight: 500;
    border-bottom: $border-width solid $purple;
    i {
      margin-right: 10px;
    }
  }
  .skills-group {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 5px 10px;
    .skill {
      padding: 10px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 25px;
      border: 2px solid $purple;
      color: $yellow;
      text-align: center;
      margin-right: 5px;
      margin-bottom: 5px
    }
  }

}