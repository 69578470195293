@import '../../index.scss';

.loader-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.8);
  &.solid {
    background: $darkest-purple;
  }
}