@import '../../index.scss';

.pj-graphics {
  .graphics-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 750px;
    margin: 0 auto;
    .pj-graphic-wrapper {
      display: inline-block;
      margin: 7.5px;
      border: $border-width solid rgba(255, 255, 255, 0);
      width: calc(50% - 15px);
      min-width: calc(50% - 15px);
      background-size: 110%;
      background-position: center;
      @include transition;
      overflow: hidden;
      box-sizing: border-box;
      &:hover, &:focus {
        outline: none;
        cursor: zoom-in;
        border: $border-width solid $purple;
        background-size: 120%;
      }
      &:after{
        content: ' ';
        display: block;
        height: 0px;
        padding-bottom: 100%;
        width: 100%;
      }
    }
    @media screen and (max-width: 800px) {
      display: block;
      .pj-graphic-wrapper {
        width: calc(100% - 15px);
        height: auto;
        &:after{
          content: ' ';
          display: block;
          height: 0px;
          padding-bottom: 100%;
          width: 100%;
        }
      }
    }
  }
 
}