@import '../../index.scss';

.writing-samples {
  .writing-sample-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .writing-sample-link {
      @include transition;
      display: flex;
      text-decoration: none;
      align-items: stretch;
      color: $white;
      min-height: 150px;
      max-width: 725px;
      border: $border-width solid $purple;
      border-radius: 10px;
      margin-bottom: 15px;
      .icon-container {
        font-size: 50px;
        min-width: 33%;
        background: $dark-purple;
        border-right: $border-width solid $purple;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition;
      }
      .writing-info {
        min-width: 0px;
        width: 100%;
        .writing-title {
          padding: 10px 10px 0px 10px;
          color: $yellow;
        }
        .writing-description {
          font-family: 'Open Sans', sans-serif;
          font-family: 12px;
          padding: 0px 10px 10px 10px;
        }
      }
      &:hover, &:focus {
        background: $dark-purple;
        .icon-container {
          font-size: 65px;
        }
      }
      @media screen and (max-width: 900px) {
        margin-bottom: 0px;
        border-radius: 0px;
        border-top: 0px;
        align-items: center;
        border-left: 0px;
        border-right: 0px;
        .icon-container {
          font-size: 30px;
          width: 75px;
          margin-left: 10px;
          height: 75px;
          max-width: 75px;
          min-width: 75px;
          max-height: 75px;
          min-height: 75px;
          border-radius: 50%;
          border: $border-width solid $purple;
          display: flex;
          align-items: center;
          justify-content: center;
          @include transition;
        }
        &:hover, &:focus {
          .icon-container {
            font-size: 40px;
          }
        }
      }
    }
  }
}