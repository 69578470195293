@import '../../index.scss';

.emotiquote {
  .emotiquote-accomplishments-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    .emotiquote-accomplishments {
      max-width: 725px;
      text-align: center;
      width: 100%;
      list-style-type: none;
      border-radius: 10px;
      margin: 0;
      display: flex;
      flex-direction: column;
      padding: 0;
      border: $border-width solid $purple;
      .emotiquote-accomplishment {
        padding: 10px;
        &.accomplishment-title {
          background: $dark-purple;
          h3 {
            margin-block-start: 0em;
            margin-block-end: 0em;
          }
        }
        &:not(:last-child) {
          border-bottom: $border-width solid $purple;
        }
        .fa {
          color: $yellow;
        }
        a {
          color: $yellow;
          text-decoration: none;
          @include transition;
          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }
      @media screen and (max-width: 800px) {
        border-radius: 0px;
        margin-bottom: 0px;
        border-width: 0px 0px $border-width 0px;
      }
    }
    @media screen and (max-width: 800px) {
      margin-bottom: 0px;
    }
  }
}