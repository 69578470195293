@import '../../index.scss';

.title {
  font-family: 'Nanum Pen Script', sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  font-size: 3em;
  color: $yellow;
  text-transform: uppercase;
}