@import '../../index.scss';


.projects-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .info-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 25px 35px 25px;
    width: 45vw;
    background: $dark-purple;
    border: $border-width solid $purple;
    padding: 15px;
    border-radius: 10px;
    .info-photo {
      min-width: 100px;
      border-radius: 100%;
      min-height: 100px;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
    }
    .info-description {
      margin-left: 25px;
      min-width: 0px;
      font-family: 'Open Sans', sans-serif;
    }
    @media screen and (max-width: 900px) {
      width: calc(100% - 30px);
      border-bottom: $border-width 0px 0px 0px;
      margin-left:0px;
      border-radius: 0px;
      margin-bottom: 0px;
      margin-right:0px;      
    }
  }
  .projects-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin: 15px 0px 50px 0px;
    border-radius: 10px;
    text-align: center;
    .searchbox {
      width: 100%;
      max-width: 200px;
      margin-right: 20px;
      position: relative;
      #search-box {
        background: rgba(255, 255, 255, 0);
        border: 0px;
        font-family: $font-stack;
        box-sizing : border-box;
        font-size: 14px;
        min-width: 100%;
        max-width: 100%;
        text-align: center;
        padding: 5px 10px;
        padding-top: 7px;
        text-transform: lowercase;
        color: $white;
        background-color: $dark-purple;
        font-weight: 500;
        border-radius: 25px;
        border: $border-width solid $purple;
        @include transition;
        &::placeholder { 
          color: rgb(170, 170, 170);
          opacity: 1;
        }
        
        &:focus, &:hover {
          border: $border-width solid $light-purple;
          outline: none;
          background-color: $purple;
          + .search-icon {
            color: $light-purple;
          }
        }
        + .search-icon {
          @include transition;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 15px;
          top: 10px;
          color: rgb(170, 170, 170);
        }
      }
      @media screen and (max-width: 450px) { 
        width: 100%;
        max-width: none;
        margin: 0;
        margin-bottom: 15px;
        #search-box {
          border-radius: 0px;
        }
      }
     
    }
    .year-filter {
      width: 100%;
      max-width: 200px;
      margin-right: 20px;
    }
    .type-filter {
      width: 100%;
      max-width: 200px;
    }
    .dropdown { 
      @include transition;
      .dropdown-heading {
        background-color: $dark-purple!important;
        font-weight: 500;
        border-radius: 25px!important;
        color: $yellow!important;
        display: flex;
        align-items: center;
        border-color: $purple!important;
        border-width: $border-width!important;
        @include transition;
        text-transform: lowercase;
  
        .dropdown-heading-value {
          color: $white!important;
          text-transform: lo;
        }
      }
  
      .dropdown-content {
        @include transition;
        background-color: $dark-purple!important;
        font-weight: 400;
        border-radius: 10px!important;
        color: $yellow!important;
        border-color: $purple!important;
        border-width: $border-width!important;
        margin-top: 5px!important;
  
        .select-item {
          background-color: $dark-purple!important;
          font-weight: 400;
          color: $white!important;
          @include transition;
          text-transform: lowercase!important;
          border-color: $purple!important;
          border-width: $border-width!important;
          input[type="checkbox"] {
            visibility: hidden;
            font-size: 1px;
            &::after {
              @include transition;
            }
            &:checked {
              &::after{
                visibility: visible!important;
                content: "✔";
                color: $yellow;
                font-size: 12px;
              }
            }
          }
          &:hover, &:focus {
            background-color: $purple!important;
            color: $white!important;
            border-color: $light-purple!important;
          }
        }
      }
      &:hover, &:focus {
        .dropdown-heading {
          background-color: $purple!important;
          color: $white!important;
          border-color: $light-purple!important;
        }
      }
    }
    @media screen and (max-width: 450px) {
      display: none;
      // flex-direction: column;
      // margin: 15px 0px;
      // width: 100%;
      // padding: 0px;
      // .year-filter {
      //   width: calc(100% - 3px);
      //   max-width: none;
      //   margin-bottom: 15px;
      //   margin-right: 0px;
      // }
      // .type-filter {
      //   width: calc(100% - 3px);
      //   max-width: none;
      // }
      // .dropdown .dropdown-heading {
      //   border-radius: 0px!important;
      // }
    }
  }
  .projects-container {
    display: flex;
    flex-wrap: wrap;
    .project {
      width: calc(50% - 15px);
      margin: 7.5px;
    }

    @media screen and (max-width: 800px) {
      display: block;
      .project {
        width: 100%;
        margin: 0px;
      }
    }
  }
}