@import '../../index.scss';

.header {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: flex-start;

  .header-city {
    background-image: url(../../media/city.svg);
    position: absolute;
    bottom: 0;
    background-size: auto 33vh;
    background-position: left bottom;
    background-repeat: repeat-x;
    left: 0;
    margin-bottom: -5px;
    height: 50vh;
    min-height: 50vh;
    width: 100vw;
    z-index: 100;
  }

  .down-button {
    position: absolute;
    bottom: 30px;
    left: auto;
    right: auto;
    z-index: 101;
    text-rendering: auto;
    color: $magenta;
    font-size: 33px;
    background: none;
    border: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    text-align: center;
    cursor: pointer;
    @include transition;

    &:hover, &:active, &:focus{
      color: $yellow;
      outline: none;
    }
  }
}

    