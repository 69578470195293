@import '../../index.scss';

.contact-page {
  .page-description {
    max-width: 550px!important;
  }
  .contact-form {
    display: flex;
    max-height: 600px;
    overflow: hidden;
    .contact-frame {
      margin-top: -75px;
      -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s; /* Firefox < 16 */
       -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
           animation: fadein 2s;
      overflow: hidden!important;
     
    }
    @media screen and (max-width: 800px) {
      max-height: none;
      overflow: hidden!important;
      max-height: 700px;
      .contact-frame {
        border: 0px;
        margin-top: 0px;
        height: 1000px;
        overflow: hidden!important;  
        noresize: noresize;
        html {
          overflow: hidden!important;
        }
      }
    }
  }
}

#form[data-loader-type="viewer"] {
  min-height: none!important;
}