@import '../../index.scss';

.about-page {
  .about-bio {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    max-width: 700px;
    margin: 0 auto;
    background: $dark-purple;
    padding: 25px;
    border-radius: 10px;
    border: $border-width solid $purple;
    a {
      color: $yellow;
      @include transition;
      text-decoration: none;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
    .about-bio-image {
      width: 33%;
      overflow: hidden;
      border-radius: 100%;
      padding-top: 33%;
      background-image: url('../../media/bio-pic.png');
      background-size: cover;
      background-position: center top;
    }
    .about-bio-info {
      min-width: 0px;
      max-width: 66%;
      margin-left: 15px;
    }
    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
      border-width: $border-width 0px;
      border-radius: 0px;
      .about-bio-image {
        width: 50%;
        padding-bottom: 18%;
      }
      .about-bio-info {
        padding-top: 20px;
        max-width: 100%
      }
    }
  }
  .skill-set {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 725px;
    margin: 0 auto;
    .skills {
      width: calc(50% - 15px);
      &:nth-child(odd) {
        margin-right: 15px;
      }
      margin-bottom: 15px;
      

    }
    @media screen and (max-width: 800px){
      flex-wrap: wrap;
      .skills {
        width: 100%;
        margin-right: 0px;
        border-radius: 0px;
        border-width: $border-width 0px 0px 0px;
        &:last-child {
          border-bottom: $border-width solid $purple;
        }
      }
    }
  }
  .leadership-set, .experience-set {
    max-width: 725px;
    margin: 0 auto;
    .experience {
      margin-bottom: 15px;
    }
    @media screen and (max-width: 800px) {
      .experience {
        margin-bottom:0px;
        border-radius: 0px;
        border-width: $border-width 0px 0px 0px;
        &:last-child {
          border-bottom: $border-width solid $purple;
        }
      }
    }
  }
}